.section-settings {
    .descriptionInput {
        min-height: 10rem !important;
        input {
            border: none;
            &:focus {
                outline: none;
            }
        }
    }
    .container-position-picker {
        left: -220px;
        top: -200px;
    }
    .delete-button-employee {
        display: flex;
        align-items: center;
        position: relative;
        justify-content: center;
        rotate: 45deg;
        i {
            width: 50%;
            height: 1px;
            background-color: #fff;
            position: absolute;
            rotate: 90deg;
            &:nth-child(2) {
                rotate: 180deg;
            }
        }
    }
}