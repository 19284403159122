.input-filter {
    margin: .5rem 0 2.5rem 0;
    margin-left: 5%;
    border: 1px solid #ccc;
    width: 40%;
    padding: .6rem 1rem;
    border-radius: 10px;
    &:focus {
        outline: none;
    }
}

.table_users {
    width: 93%;
    margin: auto;
    img {
        width: 2rem;
        height: 2rem;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
        border: 2px solid #d8d8d8;
    }
    td,
    th {
        border-top: 0;
    }
    @media (max-width:992px) {
        tr div,
        .font-semibold {
            font-size: .75rem !important;
        }
        overflow: hidden;
        width: 90%;
        margin: auto;
    }
}

#collapseShifts,
#collapsePets {
    max-height: 80vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 10px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}

.is-disabled {
    opacity: .5;
    text-decoration: line-through;
}

.collapse-button {
    span {
        width: 1.3rem;
        height: 1.3rem;
        display: inline-block;
    }
    span::before {
        content: '-';
        font-size: 1.5rem;
    }
    &.collapsed {
        span::before {
            content: '+';
        }
    }
}