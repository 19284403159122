// Main content adjustments
.main-content>.main-content-container.container-fluid {
    min-height: calc(100vh - #{$main-navbar-height + $main-footer-height});
}

.main-content-container {
    padding-left: calc(20vw + 3rem) !important;
}

@media (max-width:992px) {
    .main-content-container {
        padding-left: 1rem !important;
    }
}