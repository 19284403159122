// General utilities
// Overflow helpers
.overflow {
    &-hidden {
        overflow: hidden;
    }
    &-visible {
        overflow: visible;
    }
    &-scroll {
        overflow: scroll;
    }
    &-auto {
        overflow: auto;
    }
}

@media (max-width:1500px) {
    html {
        font-size: 14px;
    }
}

@media (max-width:1300px) {
    html {
        font-size: 13px;
    }
}

@media (min-width:2000px) {
    html {
        font-size: 18px;
    }
}

// Remove shadows
.no-shadow {
    box-shadow: none !important;
}

.loader-section {
    z-index: 99999;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;
    background-color: $simply-color;
    position: fixed;
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    width: 48px;
    height: 48px;
    border: 3px solid #FFF;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

.loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 56px;
    height: 56px;
    border-radius: 50%;
    border: 3px solid;
    border-color: #FF3D00 transparent;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.full-screen-payment-required {
    background-color: #c9c9c956;
    width: 100%;
    height: 100%;
    z-index: 9999;
    top: 0;
    display: flex;
    opacity: 1;
    backdrop-filter: blur(5px);
    .center-message {
        min-width: 40vw;
        min-height: 40vh;
        background-color: #fff;
        h2 {
            color: #1f1f1f;
            font-size: 2rem;
            font-weight: bold;
        }
        h5 {
            font-weight: 300;
            color: #1f1f1f;
            font-size: .8rem;
        }
        p {
            font-weight: 400;
            font-size: 1rem;
        }
    }
}