.content-img {
    border: 2px solid #ededed;
    border-radius: 50%;
    padding: 1rem;
    width: fit-content;
    position: relative;
    &.square {
        border-radius: 0.375rem;
        border: 1px solid #e1e5eb;
        img {
            width: 100%;
            height: 15vw;
            border-radius: 0 !important;
        }
    }
    img {
        width: 7rem;
        height: 7rem;
        object-fit: cover;
        object-position: center;
    }
    label {
        position: absolute;
        right: 0rem;
        bottom: .5rem;
        width: fit-content;
        margin-bottom: 0;
        height: fit-content;
        background-color: rgb(243, 243, 243);
        display: flex;
        align-items: center;
        margin-bottom: 0;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        width: 2.2rem;
        height: 2.2rem;
        transition: .3s ease all;
        i {
            line-height: 0;
            transition: .3s ease all;
            font-size: 1.1rem;
        }
        &:hover {
            background-color: #fff;
            border: 1px solid #1f1f1f;
        }
    }
}

.card-style-blogs {
    border-radius: 0.375rem;
    border: 1px solid #e1e5eb;
}

.button-collapse {
    .fa-chevron-down,
    .fa-plus {
        font-size: 1.2rem;
        margin-right: 1rem;
        width: fit-content;
        height: -moz-fit-content;
        height: fit-content;
        background-color: #f3f3f3;
        display: flex;
        align-items: center;
        margin-bottom: 0;
        justify-content: center;
        cursor: pointer;
        border-radius: 50%;
        width: 2.2rem;
        border: 1px solid #1f1f1f;
        height: 2.2rem;
        transition: .3s ease all;
        display: inline-flex;
        transform: rotate(-180deg);
    }
    &.collapsed {
        .fa-chevron-down {
            transform: rotate(0deg);
        }
    }
}

.modal-backdrop.show {
    opacity: .5;
}