$success:#26E087;
$pending:#E0D926;
.color-success {
    color: $success;
}

.color-pending {
    color: $pending;
}

.success-border {
    border: 3px solid $success;
}

.pending-border {
    border: 3px solid $pending;
}

.shift {
    .header-collapse {
        border-radius: 50px;
        background-color: #fff;
        padding: .5rem 1.2rem;
        .border-round {
            width: 45px;
            height: 45px;
            font-weight: 700;
            font-size: .75rem;
            text-align: center;
            border-radius: 50%;
            border: 3px solid #26A9E0;
        }
        .dog {
            border: 3px solid $pending;
            border-radius: 50%;
            overflow: hidden;
            &.success {
                border-color: $success;
            }
            img {
                object-fit: cover;
                object-position: center;
                width: 2rem;
                height: 2rem;
            }
            &:not(:first-child) {
                margin-left: -1.25rem;
            }
        }
        i {
            transition: .5s ease all;
        }
        &.collapsed {
            i {
                transform: rotate(180deg);
            }
        }
    }
    .shift-collapse {
        border: 1px solid #DBDBDB;
        .color-employee {
            width: 8px;
            height: 15px;
            display: inline-block;
            margin-right: 3px;
        }
        h5 {
            font-weight: 400;
        }
        .address {
            background-color: $pending;
            &.success {
                background-color: $success;
            }
        }
    }
    .shift_detail {
        &:not(:last-of-type) {
            border-bottom: 1px solid #d5d5d5;
        }
        .actions {
            button {
                &:focus {
                    border: none;
                    outline: none;
                }
            }
        }
    }
    .pending {
        background-color: $pending;
        border-radius: 50%;
    }
}

.modal {
    .modal-footer {
        button {
            padding: .4rem .7rem !important;
            min-width: 110px !important;
            @media (max-width: 992px) {
                font-size: .8rem;
                padding: .5rem .7rem !important;
                min-width: 90px !important;
            }
        }
    }
}

.add_shifts {
    .react-datetime-picker,
    .react-datetime-picker *,
    .react-datetime-picker *:before,
    .react-datetime-picker *:after,
    .react-datepicker-ignore-onclickoutside {
        font-family: monospace !important;
    }
    .wrapper {
        z-index: 9999 !important;
    }
    .btn-pet_add {
        z-index: 99991;
        color: #26A9E0;
        &:hover {
            color: #26A9E0;
        }
    }
    form {
        padding: 1.5rem 2.2rem;
        background-color: #fff;
        @media (max-width: 992px) {
            padding: 1.5rem 1.2rem !important;
        }
        // }
        input[type=text],
        select,
        textarea {
            border: 1px solid #C4C4C4;
            border-radius: 25px;
            font-size: .9rem;
            padding: .8rem 1.7rem;
            width: 100%;
            &:focus {
                border: 1px solid #26A9E0;
                outline: none;
            }
        }
        label {
            font-size: .8rem;
        }
        .react-datepicker-wrapper {
            width: 100%;
        }
    }
}

@media (max-width:992px) {
    .shift {
        .header-collapse {
            .border-round {
                height: 35px;
                width: 35px;
            }
            .dog {
                width: 2.2rem;
                height: 2.2rem;
                img {
                    object-fit: cover;
                    object-position: center;
                }
                &:not(:first-child) {
                    margin-left: -1rem;
                }
            }
        }
    }
}