@import url("//netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.css");
.login-block {
    // background: #26a9e0;
    /* fallback for old browsers */
    // background: -webkit-linear-gradient(to bottom, #a4a4a4, #d7d7d7);
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, #f4f4f4, #f4f4f4);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    float: left;
    width: 100%;
    height: 100vh;
    padding: 50px 0;
    font-family: $font-family-roboto-first !important;
    .row {
        background: transparent;
    }
}

.banner-sec {
    background: url("../../../assets/images/pets.jpg") no-repeat left bottom;
    background-size: cover;
    background-position: center;
    min-height: 70vh;
    border-radius: 0 10px 10px 0;
    padding: 0;
}

.container {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
}

.carousel-inner {
    border-radius: 0 10px 10px 0;
}

.carousel-caption {
    text-align: left;
    left: 5%;
}

.login-sec {
    padding: 50px 30px;
    position: relative;
}

.login-sec .copy-text {
    position: absolute;
    width: 80%;
    bottom: 20px;
    font-size: 13px;
    text-align: center;
}

.text-error {
    font-size: .8rem;
    display: block;
    margin-top: 3rem;
    &.opacity-none {
        opacity: 0;
    }
    i {
        margin-right: .5rem;
    }
}

.login-sec .copy-text i {
    color: #FEB58A;
}

.login-sec .copy-text a {
    color: #E36262;
}

// .login-sec h2 {
//     margin-bottom: 30px;
//     font-weight: 500;
//     font-size: 30px;
//     color: #DE6262;
// }
// .login-sec h2:after {
//     content: " ";
//     width: 100px;
//     height: 5px;
//     background: #FEB58A;
//     display: block;
//     margin-top: 20px;
//     border-radius: 3px;
//     margin-left: auto;
//     margin-right: auto
// }
.btn-login {
    background: #26A9E0;
    color: #fff;
    font-weight: 600;
}

.banner-text {
    width: 70%;
    position: absolute;
    bottom: 40px;
    padding-left: 20px;
}

.banner-text h2 {
    color: #fff;
    font-weight: 600;
}

.banner-text h2:after {
    content: " ";
    width: 100px;
    height: 5px;
    background: #FFF;
    display: block;
    margin-top: 20px;
    border-radius: 3px;
}

.banner-text p {
    color: #fff;
}

@media (max-width:992px) {
    .banner-sec {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
    }
    .login-block {
        margin-top: 17.5vh;
    }
}