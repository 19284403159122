// Blog Add New Post Template
// Add New Post Form
.add-new-post {
    &__editor {
        min-height: 400px;
    }
}

.jodit-container:not(.jodit_inline) .jodit-workplace {
    height: 40vh !important;
}