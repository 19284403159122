@import "utilities/general";
@import "utilities/text";
@import "utilities/borders";
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700&display=swap');
body {
    font-family: 'Raleway', sans-serif !important;
}

.mobile-auto-width {
    @media(max-width: 992px) {
        min-width: unset !important;
    }
}

.mobile-unset-width {
    @media(max-width: 992px) {
        max-width: 10rem !important;
    }
}

.custom-font {
    font-family: 'Raleway', sans-serif !important;
}