// Input groups adjustments
// Set a min width for the input groups.
// Without this some components might break (datepickers).
.input-group {
    min-width: 7.5rem;
    &.input-group-filter {
        width: 50%;
        margin-left: 3%;
        margin-bottom: 1.5rem;
        input {
            padding-top: .8rem;
            padding-bottom: .8rem;
            border-radius: 1rem !important;
        }
        .input-group-prepend {
            left: .8rem !important;
        }
        @media (max-width:992px) {
            width: 95%;
            overflow-x: hidden;
            // margin: 1rem;
            margin-bottom: 1.5rem;
            input {
                padding-top: .8rem;
                padding-bottom: .8rem;
                font-size: .8rem;
                border-radius: 1rem !important;
            }
            .input-group-prepend {
                left: .3rem !important;
            }
        }
    }
}

// Adjust the input group font size.
.input-group-text {
    font-size: $input-font-size;
}

// Adjust the seamless input group - input's padding.
.input-group.input-group-seamless>.form-control:not(:first-child),
.input-group.input-group-seamless>.custom-select:not(:first-child) {
    padding-left: 1.875rem;
}

// Adjust the input group icons.
.input-group-text i {
    transform: scale(1.1);
    &.fa {
        font-size: 0.75rem;
    }
    &.material-icons {
        top: 0;
        font-size: 0.8125rem;
    }
}