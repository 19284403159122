.separator {
    // border-left: 1px solid #e5e5e5;
    height: 100%;
    width: 1px;
    background-color: #dbdbdb;
    margin: 0rem 3rem;
    display: block;
}

.shift_modal {
    max-width: 75vw;
    width: 100%;
    background-color: transparent;
    padding: 0;
    .modal-content {
        border-radius: 1.5rem;
        .modal-body {
            background-color: transparent;
            padding: 0;
        }
        .close {
            position: absolute;
            right: 2rem;
            top: 1rem;
            font-size: 2rem;
            color: #fff;
        }
    }
    @media (max-width:992px) {
        max-width: 95vw;
        margin: auto;
        .modal-content {
            .modal-body {
                label {
                    margin-bottom: 0;
                    font-size: .75rem;
                }
            }
            .close {
                position: relative;
                right: unset;
                top: unset;
                font-size: 1.5rem;
                color: #000;
            }
        }
    }
}

.react-calendar {
    background-color: transparent;
    border: none;
    width: 20vw;
    height: 20vw;
}

.react-calendar__navigation button {
    font-size: 1.2rem;
}

.react-calendar__navigation__label__labelText--from {
    font-size: 1rem;
}

.react-calendar__month-view__weekdays__weekday,
.react-calendar__tile.react-calendar__month-view__days__day {
    font-size: .7rem;
    font-family: 'Roboto', sans-serif;
    color: #828282;
    font-weight: 500;
}

.react-calendar__month-view__days__day--weekend {
    color: #ff4343 !important;
}

.react-calendar__tile--now {
    background: $simply-color;
    // opacity: .4;
    font-weight: 700;
    color: #fff !important;
    border: 1px solid #000 !important;
}

.react-calendar__tile--active {
    background: $simply-color;
    color: #fff !important;
    opacity: 1;
}

.header-shift {
    display: flex;
    justify-content: center;
    text-align: center;
    width: fit-content;
    font-weight: 500;
    font-size: 1.1rem;
    margin-bottom: .5rem;
    padding-left: 1rem;
    margin-top: 1.5rem;
    color: #fff;
    border-radius: 20px;
    background-color: $simply-color;
    // &::before {
    //     content: '';
    //     display: flex;
    //     width: 45%;
    //     vertical-align: middle;
    //     height: 1px;
    //     background-color: #dbdbdb;
    //     align-items: center;
    //     justify-content: center;
    //     margin: auto;
    // }
    // &::after {
    //     content: '';
    //     margin: auto;
    //     align-items: center;
    //     justify-content: center;
    //     display: flex;
    //     width: 45%;
    //     height: 1px;
    //     vertical-align: middle;
    //     background-color: #dbdbdb;
    // }
}

.content-shifts {
    max-height: 70vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
        width: 5px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: #888;
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
    .status {
        div {
            margin-right: .6rem;
            font-size: .8rem;
            &.disabled {
                text-decoration: line-through;
            }
            .color {
                width: .8rem;
                height: .8rem;
                border-radius: 50%;
            }
        }
    }
    .shift-sumary {
        padding: .75rem 1rem;
        // border-top: 1px solid #dbdbdb;
        border-top: 1px solid #dbdbdb;
        .min-width-5 {
            min-width: 7rem;
        }
        .min-width-10 {
            min-width: 10rem;
        }
        .min-width-12 {
            min-width: 12rem;
        }
        .max-width-5 {
            max-width: 5rem;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
        .dropdown-toggle {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 1rem;
            &:after {
                display: none;
            }
            span {
                position: relative;
                right: 0;
                top: 0;
                width: 3px;
                height: 3px;
                border-radius: 100%;
                background-color: #828282;
                display: block;
                margin-top: .2rem;
            }
            &:focus {
                outline: none;
            }
        }
        p {
            font-size: .8rem;
        }
        .address {
            font-size: .75rem;
            max-width: 10rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

@media (max-width:992px) {
    .react-calendar {
        width: 100%;
        height: auto;
    }
    .separator {
        padding-left: 0;
        margin-left: 0;
        margin-bottom: 2rem;
        width: 100%;
        height: 2px;
    }
    &::-webkit-scrollbar {
        height: 3px;
    }
    .content-shifts {
        .shift-sumary {
            padding: .75rem 1rem .75rem 0;
        }
    }
    .day-shifts {
        .avatar {
            .dog {
                border-width: 2px;
            }
            img {
                width: 2rem !important;
                height: 2rem !important;
            }
        }
    }
}