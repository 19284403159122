        .loading_section {
            &.table-loading {
                min-width: auto;
                min-height: auto;
                height: 100%;
                width: 100%;
                opacity: 1;
            }
            min-height: 100vh;
            min-width: 100vh;
            background: rgba(11, 137, 187, 0.919);
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 999;
            &.relative {
                position: relative;
                min-height: 20vh;
                min-width: unset;
                background-color: transparent;
                width: 100%;
                &.blue .sk-chase-dot:before {
                    background-color: rgba(11, 137, 187, 0.892);
                }
            }
            // Spinner 0
            .sk-chase {
                width: 60px;
                height: 60px;
                position: relative;
                animation: sk-chase 2s infinite linear both;
            }
            .sk-chase-dot {
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                animation: sk-chase-dot 1.2s infinite ease-in-out both;
            }
            .sk-chase-dot:before {
                content: '';
                display: block;
                width: 25%;
                height: 25%;
                background-color: #fff;
                border-radius: 100%;
                animation: sk-chase-dot-before 1.2s infinite ease-in-out both;
            }
            .sk-chase-dot:nth-child(1) {
                animation-delay: -1.1s;
            }
            .sk-chase-dot:nth-child(2) {
                animation-delay: -1.0s;
            }
            .sk-chase-dot:nth-child(3) {
                animation-delay: -0.9s;
            }
            .sk-chase-dot:nth-child(4) {
                animation-delay: -0.8s;
            }
            .sk-chase-dot:nth-child(5) {
                animation-delay: -0.7s;
            }
            .sk-chase-dot:nth-child(6) {
                animation-delay: -0.6s;
            }
            .sk-chase-dot:nth-child(1):before {
                animation-delay: -1.1s;
            }
            .sk-chase-dot:nth-child(2):before {
                animation-delay: -1.0s;
            }
            .sk-chase-dot:nth-child(3):before {
                animation-delay: -0.9s;
            }
            .sk-chase-dot:nth-child(4):before {
                animation-delay: -0.8s;
            }
            .sk-chase-dot:nth-child(5):before {
                animation-delay: -0.7s;
            }
            .sk-chase-dot:nth-child(6):before {
                animation-delay: -0.6s;
            }
            @keyframes sk-chase {
                100% {
                    transform: rotate(360deg);
                }
            }
            @keyframes sk-chase-dot {
                80%,
                100% {
                    transform: rotate(360deg);
                }
            }
            @keyframes sk-chase-dot-before {
                50% {
                    transform: scale(0.4);
                }
                100%,
                0% {
                    transform: scale(1.0);
                }
            }
            // Spinner 1
            .lds-ring {
                display: inline-block;
                position: relative;
                width: 80px;
                height: 80px;
            }
            .lds-ring div {
                box-sizing: border-box;
                display: block;
                position: absolute;
                width: 64px;
                height: 64px;
                margin: 8px;
                border: 8px solid #fff;
                border-radius: 50%;
                animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
                border-color: #fff transparent transparent transparent;
            }
            .lds-ring div:nth-child(1) {
                animation-delay: -0.45s;
            }
            .lds-ring div:nth-child(2) {
                animation-delay: -0.3s;
            }
            .lds-ring div:nth-child(3) {
                animation-delay: -0.15s;
            }
            @keyframes lds-ring {
                0% {
                    transform: rotate(0deg);
                }
                100% {
                    transform: rotate(360deg);
                }
            }
        }