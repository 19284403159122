.switch-wrapper {
    width: 100%;
    position: relative;
}

.switch-wrapper>div {
    position: absolute;
    width: 100%;
}

// Main navbar adjustments
.main-navbar {
    margin-left: calc(20vw + 3rem);
    margin-right: 1.5rem;
    margin-top: 1.5rem;
    border-radius: 50px;
    padding: 0px 1.5rem;
    // border-bottom: 1px solid #c4c4c4;
    .navbar {
        height: $main-navbar-height;
        width: 100%;
        border-bottom: none !important;
        justify-content: space-between;
        align-items: center;
        form {
            align-items: center;
            width: 80%;
            div {
                width: 100%;
                align-items: center;
                background-color: #fff;
                &:hover {
                    li,
                    ul {
                        background-color: #fff;
                    }
                }
                .wrapper {
                    box-shadow: none !important;
                    border: none !important;
                    background-color: transparent;
                    height: $main-navbar-height !important;
                }
                .clear-icon {
                    width: 2rem;
                }
            }
        }
        >* {
            display: flex;
        }
        .title-brand {
            font-size: 2rem;
            font-weight: 700;
            color: #1F1F1D;
            padding-left: 1rem;
        }
        .navbar-brand {
            height: unset;
        }
        .nav-link {
            min-width: $main-navbar-nav-link-min-width;
        }
        .input-group-text {
            padding: 0 !important;
            i {
                // font-size: 1.3rem;
                line-height: 1;
                font-size: .8rem;
                color: #C4C4C4;
                svg,
                img {
                    width: 1.4rem;
                }
            }
        }
        #main-logo {
            width: 3rem !important;
            max-width: 3rem !important;
        }
        // Notifications
        .notifications {
            position: relative;
            @include media-breakpoint-down(sm) {
                position: static;
                .dropdown-menu {
                    min-width: 100% !important;
                    border-left: none;
                }
            }
            .badge {
                position: absolute;
                padding: $notifications-badge-padding-y $notifications-badge-padding-x;
                font-size: $notifications-badge-font-size;
                left: 50%;
                top: 50%;
            }
            .dropdown-menu {
                padding: 0;
                min-width: $notifications-dropdown-min-width;
                border-right: 0;
                left: auto;
                // Adjust the dropdown menu on smaller screens.
                @include media-breakpoint-down(sm) {
                    left: 0;
                    right: 0;
                }
                .dropdown-item {
                    white-space: normal;
                    display: flex;
                    flex-flow: row;
                    padding-top: $notifications-dropdown-item-padding-top;
                    padding-bottom: $notifications-dropdown-item-padding-bottom;
                    border-bottom: $notifications-dropdown-item-border-bottom;
                    &:last-child {
                        border-bottom: 0;
                    }
                }
                .notification {
                    &__icon-wrapper {
                        display: flex;
                        padding: 0 5px;
                    }
                    &__icon {
                        background-color: $notifications-icon-background-color;
                        box-shadow: $notifications-icon-box-shadow;
                        width: $notifications-icon-width;
                        height: $notifications-icon-height;
                        line-height: 0;
                        display: block;
                        text-align: center;
                        margin: auto;
                        border-radius: 50%;
                        i {
                            color: $notifications-icon-color;
                            line-height: $notifications-icon-line-height;
                            font-size: $notifications-icon-font-size;
                            margin: 0;
                        }
                    }
                    &__content {
                        padding: $notification-content-padding;
                        p {
                            margin: 0;
                            line-height: 1.5;
                            font-size: $notification-content-paragraph-font-size;
                        }
                    }
                    &__category {
                        font-size: $notification-category-font-size;
                        color: $notification-category-color;
                        letter-spacing: $notification-category-letter-spacing;
                        display: inline-block;
                        text-transform: uppercase;
                        margin-bottom: 5px;
                        font-weight: 500;
                    }
                    // All notifications link.
                    &__all {
                        display: block;
                        font-weight: 500;
                        font-size: 11px;
                        border-bottom-left-radius: $border-radius;
                        border-bottom-right-radius: $border-radius;
                    }
                }
            }
        }
        // User avatar
        .user-avatar {
            width: 2.5rem;
            height: 2.5rem;
            object-position: center;
            object-fit: cover;
        }
        // Navbar
        .navbar-nav {
            .dropdown-menu {
                position: absolute;
                right: 0;
                width: 100%;
                border-top-left-radius: 0;
                border-top-right-radius: 0;
                border-top: none;
            }
        }
        .nav-link-icon i {
            line-height: $main-navbar-nav-link-icon-line-height;
        }
    }
}

// Main navbar shadow.
.main-content>.main-navbar,
.main-content .header-navbar {
    box-shadow: $main-navbar-box-shadow;
}

@media (max-width:992px) {
    .main-navbar {
        padding-left: 0;
        margin-left: .4rem !important;
        margin-right: .4rem !important;
        .navbar {
            width: 100%;
            .navbar-nav {
                border-left: none !important;
            }
        }
    }
}