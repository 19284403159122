// Content Post Component
// Used in templates: Components > Blog Posts
// Blog Post Cards
.card-post {
    .card-body {
        padding: $card-post-padding;
        .card-text {
            min-height: 3.5rem
        }
    }
    .card-footer,
    .card-header {
        padding-left: $card-post-padding-x;
        padding-right: $card-post-padding-x;
    }
    &__image {
        position: relative;
        min-height: $card-post-image-min-height !important;
        border-top-left-radius: $card-border-radius;
        border-top-right-radius: $card-border-radius;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
    &__author-avatar {
        width: $card-post-author-avatar-width;
        height: $card-post-author-avatar-height;
        box-shadow: $card-post-author-avatar-box-shadow;
        display: block;
        background-position: center;
        background-size: cover;
        border-radius: 50%;
        text-indent: -9999px;
        // Author Avatar -- Small Modifier
        &--small {
            width: $card-post-author-avatar-small-width;
            height: $card-post-author-avatar-small-height;
        }
    }
    &__author-name {
        font-weight: 500;
    }
    &--aside {
        display: flex;
        flex-flow: row;
        .card-post__image {
            border-top-right-radius: 0;
            border-top-left-radius: $card-border-radius;
            border-bottom-left-radius: $card-border-radius;
            min-width: 180px;
        }
    }
    &--1 {
        .card-post__author,
        .card-post__category {
            position: absolute;
        }
        .card-post__author {
            transform: $card-post-v1-author-transform;
            margin-left: $card-post-v1-author-margin-left;
            position: absolute;
            bottom: 0;
        }
        .card-post__category {
            top: $card-post-v1-category-top;
            right: $card-post-v1-category-right;
            position: absolute;
            text-transform: uppercase;
        }
        .card-body {
            padding-top: $card-post-v1-body-padding-top;
        }
        // Card Post Aside Adjustments
        &.card-post--aside {
            .card-body {
                padding: $card-post-aside-v1-body-padding;
            }
            .card-post__author {
                left: $card-post-aside-v1-author-left;
                bottom: $card-post-aside-v1-author-bottom;
                transform: none;
                margin: 0;
            }
            .card-post__category {
                right: initial;
                top: $card-post-aside-v1-category-top;
                left: $card-post-aside-v1-category-left;
            }
        }
    }
    &.card-create {
        padding-bottom: 2rem;
        input {
            border: none;
            width: 100%;
            &.bold {
                font-weight: 500;
            }
            &:focus {
                outline: none;
            }
            &::placeholder {
                color: rgb(214, 214, 214);
            }
            &.gray-color {
                color: rgb(214, 214, 214) !important;
            }
        }
        .content-img {
            border: none;
            padding: 0;
            width: 100%;
            position: relative;
            border-radius: 0;
            &.notImage {
                border: 1px dashed #797979;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                background-color: rgb(230, 230, 230);
                opacity: 0.3;
            }
            label {
                right: 1rem;
            }
            img {
                border: none;
                width: 100%;
                border-top-left-radius: 0.625rem;
                border-top-right-radius: 0.625rem;
                height: 10.3125rem !important;
            }
            position: relative;
            min-height: 10.3125rem !important;
            border-top-left-radius: 0.625rem;
            border-top-right-radius: 0.625rem;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        .content-button {
            bottom: -1.3rem;
            width: 100%;
            // left: calc(50% - 5vw);
            .btn {
                opacity: 1;
                background-color: rgb(11, 137, 187);
                width: 10vw;
                &:hover {
                    background-color: #fff;
                }
            }
        }
    }
    .btn.actions {
        padding: .4rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
            font-size: 1rem;
        }
    }
    .yesOrNot {
        display: flex;
        button {
            background-color: transparent;
            border: none;
            border: 1px solid;
            font-size: .9rem;
            width: 1.5rem;
            padding: .5rem;
            height: 1.5rem;
            display: flex;
            vertical-align: middle;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            transition: .2s ease all;
            line-height: 1;
            &.bg-danger {
                color: #fff;
                &:hover {
                    background-color: #fff !important;
                    color: #c4183c !important;
                }
            }
            &.bg-success {
                color: #fff;
                &:hover {
                    background-color: #fff !important;
                    color: #17c671 !important;
                }
            }
        }
    }
}

.btn {
    background-color: rgba(11, 137, 187, 0.892);
    padding: .8rem 1.3rem;
    color: #fff;
    font-weight: 600;
    border-radius: 20px;
    border: 2px solid transparent;
    transition: .3s ease all;
    &:hover {
        border: 2px solid rgba(11, 137, 187, 0.892);
        color: rgba(11, 137, 187, 0.892);
        background-color: transparent;
    }
}