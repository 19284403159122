// Page headers
//
// Used across the UI kit to emphasize each page by using a title
// and optional description.
.page-header {
    .page-title {
        font-size: 2.25rem;
        font-weight: 500;
        line-height: $page-title-line-height;
        margin: $page-title-margin;
        padding: $page-title-padding;
        color: #1f1f1f;
        @include media-breakpoint-down(sm) {
            font-size: $page-title-sm-font-size;
        }
    }
    .page-subtitle {
        letter-spacing: 1.5px;
        color: $page-subtitle-color;
        font-size: $page-subtitle-font-size;
        font-weight: 600;
        @include media-breakpoint-down(sm) {
            font-size: $page-subtitle-font-size-sm;
            font-weight: $page-subtitle-font-weight-sm;
        }
    }
}