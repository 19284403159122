// Main sidebar
.main-sidebar {
    top: 0;
    width: 20vw;
    position: fixed;
    height: $main-sidebar-height;
    background: $main-sidebar-background;
    z-index: 1000;
    will-change: $main-sidebar-will-change;
    transition: $main-sidebar-transition;
    box-shadow: $side-shadow;
    @media (max-width: 992px) {
        transform: translateX(-1000%);
        width: 100%;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        box-shadow: none;
        &.open-mobile {
            transform: translateX(0);
        }
    }
    .title-left {
        margin-left: 1.5rem !important;
    }
    .wrapper {
        box-shadow: none !important;
        font-size: 1rem;
    }
    // Opened state
    &.open {
        // transform: translateX(0%);
        // width: 100%;
        // box-shadow: $side-shadow;
    }
    // Toggle sidebar
    .toggle-sidebar {
        position: absolute;
        right: 0;
        height: 100%;
        padding: $main-sidebar-toggle-padding;
        font-size: $main-sidebar-toggle-font-size;
        border-left: $main-sidebar-toggle-border-left;
        &:hover {
            cursor: pointer;
        }
    }
    // Sidebar navbar brand
    .navbar-brand {
        overflow: hidden;
        height: $main-navbar-height;
        font-size: $main-navbar-brand-font-size;
        @include media-breakpoint-down(md) {
            font-size: 90%;
        }
    }
    .nav-wrapper {
        overflow-y: auto;
        overflow-x: hidden;
        border-top: 1px solid #e1e5eb;
        height: $main-sidebar-nav-wrapper-height;
    }
    // Nav
    .nav {
        .nav-item,
        .nav-link {
            white-space: nowrap;
            min-width: 100%;
            max-width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family: $main-sidebar-nav-link-font-family;
            will-change: $main-sidebar-nav-link-will-change;
            transition: $main-sidebar-nav-link-transition;
            font-size: $main-sidebar-nav-link-font-size;
        }
        .nav-item {
            .nav-link {
                border-bottom: $main-sidebar-nav-link-border;
                font-weight: $main-sidebar-nav-link-font-weight;
                color: $main-sidebar-nav-link-color;
                padding: 1.3rem $main-sidebar-nav-link-padding-x;
                display: flex;
                align-items: center;
                i {
                    width: 2rem;
                    // font-size: 90%;
                    color: #1f1f1f;
                    display: inline-block;
                    vertical-align: middle;
                    will-change: $main-sidebar-nav-link-icon-will-change;
                    color: $main-sidebar-nav-link-icon-color;
                    transition: $main-sidebar-nav-link-icon-transition;
                    margin-right: $main-sidebar-nav-link-icon-margin-right;
                    font-size: 1.2rem;
                    &.icon-pets {
                        font-size: 1rem;
                    }
                    &.icon-home {
                        font-size: 1.35rem;
                    }
                    &.icon-shifts {
                        font-size: 1.1rem;
                    }
                }
            }
            &.active,
            .nav-link.active,
            &:hover,
            .nav-link:hover {
                box-shadow: inset 0.1875rem 0 0 #26a9e0;
                background-color: $main-sidebar-nav-link-active-background-color;
                color: #26a9e0;
                i {
                    color: #26a9e0;
                }
            }
        }
        // Nav Item -- No borders
        &--no-borders {
            .nav-item .nav-link {
                border-bottom: $main-sidebar-nav-no-borders-nav-link-border-bottom;
            }
            .dropdown-menu {
                box-shadow: $main-sidebar-nav-no-borders-dropdown-menu-box-shadow;
                .dropdown-item:first-child {
                    border-top: $main-sidebar-nav-no-borders-dropdown-item-first-border-top;
                }
            }
        }
    }
    // Dropdown menu
    .dropdown-menu {
        position: static !important;
        transform: translate(0) !important;
        box-shadow: none;
        border-radius: 0;
        width: 100%;
        border: none;
        padding: 0;
        box-shadow: $main-sidebar-dropdown-menu-box-shadow;
        .dropdown-item {
            padding: $main-sidebar-dropdown-item-padding-y $main-sidebar-dropdown-item-padding-x;
            border-bottom: $main-sidebar-dropdown-item-border;
            color: $main-sidebar-dropdown-item-color;
            font-size: $main-sidebar-dropdown-item-font-size;
            font-weight: $main-sidebar-dropdown-item-font-weight;
            @media (-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi) {
                font-weight: $main-sidebar-dropdown-item-font-weight-retina;
            }
            &:hover,
            &.active {
                color: $main-sidebar-dropdown-item-active-color;
            }
            &:hover {
                background: $main-sidebar-dropdown-item-background-hover;
            }
            &.active {
                background-color: $main-sidebar-dropdown-item-background-color-active;
            }
            &:last-of-type {
                border-bottom: $main-sidebar-dropdown-item-last-border;
            }
        }
        .dropdown-divider {
            margin: 0;
        }
    }
    // Dropdown toggle
    .dropdown-toggle {
        position: relative;
        &::after {
            background-image: $dropdown-icon-image;
            background-position: $dropdown-icon-background-position;
            width: $dropdown-icon-width;
            height: $dropdown-icon-height;
            transition: $dropdown-icon-transition;
            border: none;
            position: absolute;
            top: 50%;
            right: 0.625rem;
            transform: translateY(-50%);
            opacity: .1;
            will-change: transform;
        }
    }
    .dropdown.show {
        .dropdown-toggle::after {
            transform: translateY(-50%) rotateZ(180deg);
        }
    }
    &__search {
        @include media-breakpoint-down(sm) {
            box-sizing: border-box;
            border-right: 0 !important;
            padding: 0.625rem 0;
            border-bottom: 1px solid $border-color;
            .input-group {
                margin: 0 !important;
            }
        }
    }
}

//
// Common styles for both main navbar and
// main sidebar search elements.
//
.main-navbar__search,
.main-sidebar__search {
    .input-group-prepend {
        .input-group-text {
            font-size: 0.6875rem;
            padding: 0.75rem 1.0625rem;
        }
    }
    input.form-control {
        border: none;
        font-size: 0.8125rem;
        border-radius: 0;
        @include hover-focus {
            box-shadow: none;
        }
    }
}