.metrics_page {
    .sidebar-filter {
        background: #fff;
        border-left: 1px solid $simply-color;
        position: fixed;
        right: 0rem;
        padding-top: 10vh;
        width: 30vw;
        padding: 0vh 3rem 10vh 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 9999;
        top: 0;
        bottom: 0;
        transition: .3s ease-in-out all;
        .open-sidebar-filter {
            width: 3rem;
            cursor: pointer;
            height: 3rem;
            transform: rotate(0deg);
            background-color: $simply-color;
            border-radius: 50%;
            position: absolute;
            top: calc(50vh - 1rem);
            left: -1.7rem;
            z-index: 99999;
            color: #fff;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            font-size: 1rem;
            line-height: 1.1;
            padding-bottom: 2px;
            border: 1px solid #fff;
            transition: .3s ease-in-out all;
            &:hover {
                background-color: #fff;
                color: $simply-color;
                border: 1px solid $simply-color;
            }
        }
        h2 {
            font-size: 2rem;
            margin-bottom: .5rem;
        }
        p {
            margin-bottom: 1rem;
            padding-bottom: 1rem;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.171);
        }
        .rdrStaticRangeLabel,
        .rdrInputRange {
            padding: 10px 20px 10px 0px;
        }
        &.close {
            transform: translateX(28vw);
            opacity: 1 !important;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-color: #fff;
                opacity: 1;
                // border-radius: 50%;
                z-index: 9999;
            }
            .open-sidebar-filter {
                transform: rotate(180deg);
            }
        }
        .btn-simply {
            background-color: $simply-color;
            cursor: pointer;
            color: #fff;
            padding: .7rem 2rem;
            border-radius: 20px;
            border: 1px solid $simply-color;
            width: fit-content;
            &:hover {
                background-color: #fff;
                color: $simply-color;
                border: 1px solid $simply-color;
            }
        }
    }
}

@media (max-width:992px) {
    .metrics_page {
        .sidebar-filter {
            width: 95%;
            &.close {
                transform: translateX(95%);
            }
            .rdrDateRangePickerWrapper {
                transform: scale(.8);
                justify-content: end;
            }
            .rdrDefinedRangesWrapper {
                display: none;
            }
        }
    }
}